.experience {
    padding: 3rem 2rem;
    background-color: #ece7f0;
    text-align: center;
  }
  
  .experience h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 2rem;
  }
  
  .experience-cards {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .experience-card {
    background-color: #d4c3b8;
    padding: 1.5rem;
    border-radius: 8px;
    width: 300px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  .experience-card blockquote {
    font-size: 1.125rem;
    color: #555;
    font-style: italic;
    margin: 0 0 1rem 0;
  }
  
  .experience-card p {
    font-size: 1rem;
    color: #333;
    margin: 0;
    font-weight: bold;
  }
  
  .download-button {
    margin-top: 2rem;
    padding: 0.75rem 1.5rem;
    background-color: #333;
    color: #fff;
    border-radius: 20px;
    text-decoration: none;
    font-weight: bold;
  }
  
  .download-button:hover {
    background-color: #555;
  }
  .experience-card ul {
    padding-left: 1.5rem;
    margin-top: 0.5rem;
  }
  
  .experience-cards {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  @media (min-width: 769px) {
    .experience-cards {
      flex-direction: row;
    }
  }
  