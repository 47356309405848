.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
/* managing  layout */

/* General section styling */
section {
  padding: 2rem 1rem;
  background-color: #f5f5f5;
  color: #333;
  border-bottom: 4px solid black;
}

@media (max-width: 768px) {
  section {
    padding: 1.5rem 1rem;
  }
}

section:last-of-type {
  border-bottom: none; /* Removes the border for the last section */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
