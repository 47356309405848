.achievements {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  justify-content: center;
  gap: 2rem; /* Adjusts space between cards */
  /* padding: 3rem 2rem; */
  background-color: #1e2130;
  color: #fff;
}

.achievement {
  text-align: center;
  padding: 1rem;
  background-color: #1e2130;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: 250px; Ensure equal width */
  width: 100%;
  max-width: 300px;
  min-height: 300px; /* Ensure equal height */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow for depth */
}

.achievement img {
  width: 50px;
  height: 50px;
  margin-bottom: 1rem;
  filter: brightness(0) invert(1);
}

.achievement h3 {
  font-size: 1.25rem;
  margin: 0.5rem 0;
  color: #fff;
}

.achievement p {
  font-size: 1rem;
  color: #bbb;
  line-height: 1.4;
}
@media (min-width: 769px) {
  .achievements {
    flex-direction: row;
    justify-content: center;
  }

  .achievement {
    width: 250px;
  }
}