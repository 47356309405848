.contact {
  text-align: center;
  padding: 20px;
  background-color: #f7c6c7; /* Soft background color */
}

.contact h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.contact p {
  font-size: 16px;
  margin: 5px 0;
}

.email-button {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #e57373;
  background-color: #ece9e5; /* Button color */
  border: 2px solid black; /* Black border around button */
  border-radius: 25px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  max-width: 200px; /* Sets a max width to prevent it from stretching too large */
  width: 100%; /* Allows it to fill available space within the max width */
  box-sizing: border-box; /* Ensures padding doesn’t cause overflow */
}

.email-button:hover {
  background-color: #d32f2f; /* Darker color on hover */
  color: white;
}

/* Copyright styling */
.copyright {
  font-size: 14px;
  margin-top: 20px;
  color: #333;
  opacity: 0.8;
}
@media (max-width: 768px) {
  .email-button {
    width: 100%;
    max-width: 200px; /* Restricts button width even on small screens */
  }
}