.honors-and-awards {
  padding: 3rem 2rem;
  background-color: #c6c2cd; /* Background color */
  color: #333;
  text-align: center;
}

.honors-and-awards h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 2rem;
}

.honors-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
}

.honor-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 90%;
  max-width: 300px;
  background: #fff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.honor-item:hover {
  transform: scale(1.02);
}

.honor-icon {
  width: 40px;
  height: 40px;
  margin-bottom: 1rem;
  filter: brightness(0) invert(1);
}

.honor-details h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.honor-details .issuer {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.honor-details .description {
  font-size: 1rem;
  color: #555;
}

@media (min-width: 768px) {
  .honors-list {
      flex-direction: row;
      gap: 2rem;
  }

  .honor-item {
      flex: 1 1 300px;
      max-width: 300px;
      margin: 1rem;
  }
}
