/* Navigation styling */
.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: #f5f5f5;
  border-bottom: 4px solid black;
}

.nav-left {
  display: flex;
  align-items: center;
}

.nav-left h1 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-left: 0.5rem;
}

.hamburger {
  display: none;
  font-size: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
}

/* Navigation links */
.nav-links {
  display: flex;
  gap: 1.5rem;
}

.nav-right {
  display: flex;
  gap: 1rem;
}

.nav-right .button-link {
  padding: 0.5rem 1rem;
  background-color: #000;
  color: #fff;
  border-radius: 20px;
  text-decoration: none;
  font-weight: bold;
}

.nav-right .button-link:hover {
  background-color: #333;
}

/* Mobile styles */
@media (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    top: 60px;
    left: 10px;
    background-color: #f5f5f5;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .nav-links.open {
    display: flex;
  }

  .hamburger {
    display: block;
    margin-right: 0.5rem;
  }

  .nav-right {
    display: flex;
    gap: 0.5rem;
  }
}
