.projects {
  padding: 3rem 0;
  background-color: #f5f5f5;
  text-align: center;
}

.projects h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 3rem;
}

.project-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  padding: 0 2rem;
}

.project-card {
  background-color: #c1b580;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
  transition: transform 0.3s, background-color 0.3s;
}

.project-card:hover {
  transform: scale(1.05);
  background-color: #e0d6a1;
}

.project-card h3 {
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.project-card p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1rem;
}

.project-card a {
  font-size: 1rem;
  color: #333;
  text-decoration: underline;
  font-weight: bold;
}
