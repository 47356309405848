.about-me {
  padding: 3rem 2rem;
  background-color: #c6c2cd; /* Updated background color */
  color: #333;
}

.about-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem; /* Adds consistent space between text and image */
}

.about-text {
  max-width: 100%;
  text-align: center;
}

.about-text h2 {
  display: inline-block;
  font-size: 2.5rem;
  color: #000000;
}

.about-text h2::after {
  content: '|';
  animation: blink 1s step-start infinite;
  color: #000000;
  margin-left: 5px;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.about-text p {
  font-size: 1.25rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.say-hi-button,
.resume-button {
  display: inline-flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  background-color: #edb7b3;
  color: #333;
  text-decoration: none;
  border-radius: 20px;
  font-weight: bold;
  font-size: 1.5rem;
  border: 2px solid #333;
  margin-right: 1rem;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.say-hi-button:hover,
.resume-button:hover {
  background-color: #f4c1bc;
}

.mail-icon {
  width: 24px;
  height: 24px;
  margin-left: 10px;
  filter: invert(0.2);
}

.about-image {
  display: flex;
  justify-content: center;
  padding: 1rem; /* Adds padding around the image */
}

.about-image img {
  width: 300px;
  height: auto;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (min-width: 769px) {
  .about-content {
      flex-direction: row;
      align-items: flex-start; /* Aligns the content to the start on larger screens */
  }

  .about-text {
      max-width: 50%;
      text-align: left;
  }

  .about-image {
      padding: 0; /* Remove extra padding for larger screens */
  }

  .about-image img {
      width: 350px; /* Larger size for desktop */
  }
}
